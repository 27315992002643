// greenfield

$tag-background-color: $secondary-background !default;
$tag-color: $secondary-dark !default;
$tag-weight: $weight-normal !default;
$tag-radius: 2px !default;
$tag-delete-margin: 1px !default;
$tag-small-padding: 0.125rem 0.5rem !default;

.tags {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;

	.tag {
		margin-bottom: 0.25rem;

		&:not(:last-child) {
			margin-#{$user-right}: 0.25rem;
			// override margin-left/right with universal text direction support in modern browsers
			margin-inline-start: 0;
			margin-inline-end: 0.25rem;
		}
	}

	&:last-child {
		margin-bottom: -0.25rem;
	}

	&:not(:last-child) {
		margin-bottom: 1rem;
	}

	&.has-addons {
		.tag {
			margin-#{$user-right}: 0;

			&:not(:first-child) {
				border-bottom-#{$user-left}-radius: 0;
				border-top-#{$user-left}-radius: 0;
			}

			&:not(:last-child) {
				border-bottom-#{$user-right}-radius: 0;
				border-top-#{$user-right}-radius: 0;
			}
		}
	}

	&.is-centered {
		justify-content: center;

		.tag {
			margin-#{$user-right}: 0.25rem;
			margin-#{$user-left}: 0.25rem;
		}
	}

	&.is-right {
		justify-content: flex-end;

		.tag {
			&:not(:first-child) {
				margin-#{$user-left}: 0.5rem;
			}

			&:not(:last-child) {
				margin-#{$user-right}: 0;
			}
		}
	}
}

.tag:not(body) {
	align-items: center;
	background-color: $tag-background-color;
	border-radius: $tag-radius;
	color: $tag-color;
	display: inline-flex;
	font-size: $font-size-small;
	font-weight: $tag-weight;
	height: 2em;
	justify-content: center;
	line-height: 1.5;
	padding-#{$user-left}: 0.5em;
	padding-#{$user-right}: 0.5em;
	white-space: nowrap;
	border: 1px solid $border-white-high-contrast;

	&.white-space-pre {
		white-space: pre;
	}

	&.is-small {
		height: 1.666667em;
		font-size: $font-size-extra-small;
	}

	&.is-large {
		height: 1.666667em;
		font-size: $font-size-large;
	}

	// Replace the above with the below function when colors are sorted
	@each $name, $color-set in $colors {
		$base: nth($color-set, $effect-index-base);
		$invert: nth($color-set, $effect-index-invert);
		$background: nth($color-set, $effect-index-background);
		$dark: nth($color-set, $effect-index-dark);

		&.is-#{$name} {
			background-color: $base;
			color: $invert;

			.delete {
				&:before,
				&:after {
					color: $dark;
					border-color: transparent;
				}
			}
		}
	}

	&.is-black {
		background-color: $black-static;
		color: $white-static;

		.delete {
			&:before,
			&:after {
				color: $white-static;
				border-color: transparent;
			}
		}
	}

	.delete {
		margin-#{$user-left}: 0.25rem;
		margin-#{$user-right}: -0.375rem;

		&:before,
		&:after {
			color: $text;
			border-color: transparent;
		}
	}

	.icon {
		&:first-child:not(:last-child) {
			margin-#{$user-right}: 0.1875em;
		}

		&:last-child:not(:first-child) {
			margin-#{$user-left}: 0.1875em;
		}
	}

	// Modifiers

	&.is-delete {
		margin-#{$user-left}: $tag-delete-margin;
		padding: 0;
		position: relative;
		width: 2em;

		&:before,
		&:after {
			background-color: currentColor;
			content: '';
			display: block;
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translateX(-50%) translateY(-50%) rotate(45deg);
			transform-origin: center center;
		}

		&:before {
			height: 1px;
			width: 50%;
		}

		&:after {
			height: 50%;
			width: 1px;
		}

		&:hover,
		&:focus-visible {
			opacity: 95%;
		}

		&:active {
			opacity: 95%;
		}
	}
}

a.tag {
	&:hover {
		text-decoration: underline;
	}
}
